import { SEOHead } from '~/components/business/SEOHead'
import { MyError } from '~/components/ui/MyError'

function Error() {
  return (
    <>
      <SEOHead title={404} />
      <MyError statusCode={404} />
    </>
  )
}

export default Error
