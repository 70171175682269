import { Button } from 'antd-mobile'
import { useRouter } from 'next/router'

export const MyErrorConent: React.FC<{
  statusCode: number
  traceid?: string
}> = ({ statusCode, traceid }) => {
  const router = useRouter()
  return (
    <div className="text-center px-6">
      <div className="mb-2">
        <img
          className="w-[100px] mx-auto"
          src="/images/common/error-icon@2x.png"
          alt=""
        />
      </div>
      <div className="text-xs font-semibold text-neutral-400 mb-2">
        {statusCode === 404 ? '内容不存在' : '该网页无法正常运作'}
        <br />
        {traceid ? `（错误码：${traceid}）` : ''}
      </div>
      <Button
        className="btn btn-primary btn-sm !mx-auto"
        onClick={() => router.replace('/')}
      >
        <a className="text-neutral-800">返回首页</a>
      </Button>
    </div>
  )
}
export const MyError: React.FC<{
  statusCode: number
  traceid?: string
}> = ({ statusCode, traceid }) => {
  return (
    <div className="fixed top-0 left-0 right-0 bottom-0">
      <div className="h-full w-full flex items-center justify-center">
        <MyErrorConent statusCode={statusCode} traceid={traceid} />
      </div>
    </div>
  )
}
